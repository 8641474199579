<template>
  <PurchaseDeliveryPalletNoComponent
    goods-type="others"
  />
</template>
<script>
import PurchaseDeliveryPalletNoComponent from './PurchaseDeliveryPalletNoComponent'
export default {
  name: 'EmptView',
  components: {
    PurchaseDeliveryPalletNoComponent
  },
  data: function () {
    return {
      isLoading: false,
      userInfo: null
    }
  },
  created () {

  },
  mounted () {
  },
  updated () {
  },
  methods: {
  }
}
</script>

<style lang="scss">

</style>
